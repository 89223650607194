<template>
  <div class="p-4">
    <div class="mb-4" v-html="latestNationalPlan.target_guideline"></div>
    <p>
      <strong
        >หลักคิดเกี่ยวกับ &ldquo;เป้าประสงค์ด้านพลังงานของจังหวัด&rdquo;</strong
      >
    </p>
    <ul>
      <li>
        <strong>เป้าประสงค์</strong>เป็นข้อความที่บ่งบอก
        <strong>&ldquo;ผลเปลี่ยนแปลงที่ต้องการให้เกิดขึ้น&rdquo;</strong>
        สืบเนื่องจาก<strong>ประเด็นพลังงานของจังหวัด</strong>
      </li>
      <li>
        เป้าประสงค์ด้านพลังงานของจังหวัด ต้องมีความ<strong
          >เชื่อมโยงกับยุทธศาสตร์พลังงานระดับประเทศ</strong
        ><strong>/พน.</strong> (ระบุได้ว่าจัดอยู่ภายใต้ยุทธศาสตร์/แผนงานใดของ
        พน.) และต้องเชื่อมโยงได้กับยุทธศาสตร์/แผนเชิงพื้นที่
        (จังหวัด/กลุ่มจังหวัด/ภาค)
      </li>
      <li>
        <strong>เป้าประสงค์</strong
        >ต้องแสดงถึงทิศทางการเปลี่ยนแปลง(จากสภาพเดิม)อย่างชัดเจน เช่น เพิ่มขึ้น
        ครอบคลุมทั่วถึงมากขึ้น ครอบคลุมทุกหมู่บ้าน หรือลดน้อยลง หมดไป
        มีความปลอดภัยสูงขึ้น ฯลฯ
      </li>
      <li>
        เป้าประสงค์ควรมุ่งไปที่<strong>ผลเปลี่ยนแปลงขั้นสุดท้าย</strong>ที่ต้องการให้เกิดขึ้นเมื่อสิ้นสุดระยะเวลาของแผนฯ
        โดยอย่างน้อยต้องถึงขั้นผลเปลี่ยนแปลงพฤติกรรม (behavioral change เช่น
        ลดปริมาณพลังงานที่ใช้ต่อหน่วยผลผลิต,
        เปลี่ยนไปใช้เชื้อเพลิงชีวมวลแทนเชื้อเพลิงฟอสซิล)
        และอาจก้าวถึงขั้นผลที่เป็นรูปธรรมในเชิงเศรษฐกิจและสังคม (tangible
        socio-economic outcome เช่น ลดต้นทุนพลังงานต่อหน่วยผลผลิต)
      </li>
    </ul>
    <p>
      <strong>แนวเขียน &ldquo;เป้าประสงค์ด้านพลังงานของจังหวัด&rdquo;</strong>
    </p>
    <ul>
      <li>
        ข้อความเป้าประสงค์ต้องสื่อถึง<strong>ทิศทางการเปลี่ยนแปลง </strong
        >แต่ไม่ต้องระบุค่าตัวเลขเป้าหมาย
        เนื่องจากค่าตัวเลขจะไปปรากฏในเป้าหมายผลลัพธ์ตามตัวชี้วัดความสำเร็จ
      </li>
      <li>
        ข้อความเป้าประสงค์ควรสื่อถึง<strong>ลักษณะเฉพาะของพื้นที่</strong> เช่น
        พลังงานทดแทนจากวัสดุพืชไร่เหลือใช้(แทนที่จะใช้คำกลาง ๆ เช่น
        &ldquo;พลังงานทดแทน&rdquo;
        โดยไม่บ่งชัดถึงชนิดของพลังงานทดแทนที่มีศักยภาพจริงในพื้นที่)
        เพื่อให้เป็นภาพแห่งความสำเร็จที่ชัดเจนในความรู้สึกของผู้มีส่วนได้ส่วนเสียที่คุ้นเคยกับพื้นที่
      </li>
      <li>
        ข้อความเป้าประสงค์ควรสื่อถึง<strong>ขอบเขตของกลุ่มเป้าหมาย</strong>ที่สมเหตุสมผล
        ไม่เขียนเปิดกว้างเกินไป
        ควรสอดคล้องกับกลุ่มเป้าหมายที่จำเป็นต้องเร่งปรับปรุงเปลี่ยนแปลง เช่น
        โรงงานผลิตอาหารแปรรูป, กลุ่มเกษตรกรในที่ราบสูง, พื้นที่เกาะ ฯลฯ
      </li>
    </ul>
    <p><strong>ตัวอย่างข้อเขียน &ldquo;เป้าประสงค์&rdquo;</strong></p>
    <p>
      &ldquo;สามารถมีการสำรวจก๊าซธรรมชาติในพื้นที่จังหวัดได้
      โดยประชาชนให้การยอมรับและได้รับผลประโยชน์ที่เป็นธรรม&rdquo;
    </p>
    <p>
      &ldquo;มีการผลิตและการใช้พลังงานทดแทนเพิ่มขึ้น จากพืชพลังงาน,
      วัสดุเหลือทิ้งจากภาคเกษตรกรรม และขยะชุมชน&rdquo;
    </p>
    <p>
      &ldquo;พื้นที่เกาะ....
      ผลิตไฟฟ้าเองได้เพียงพอสำหรับราษฎรเกาะและนักท่องเที่ยว&rdquo;
    </p>

    <p>
      <strong
        >หลักคิดเกี่ยวกับตัวชี้วัดความสำเร็จ หน่วยวัด และค่าเป้าหมาย</strong
      >
    </p>
    <ul>
      <li>
        เมื่อได้กำหนดเป้าประสงค์(=ผลเปลี่ยนแปลง)ที่ต้องการให้เกิดขึ้นในพื้นที่แล้ว
        ก็จะต้องมีแนวทางที่จะประเมินความสำเร็จ(=การบรรลุเป้าประสงค์)
        โดยกำหนด<strong>ตัวชี้วัดความสำเร็จ สำหรับแต่ละเป้าประสงค์</strong>
      </li>
      <li>
        เป้าประสงค์แต่ละข้อ จะต้องมี<strong
          >ตัวชี้วัดความสำเร็จอย่างน้อย 1 ตัว</strong
        >
        และเป็นตัววัดที่<strong>สะท้อนผลเปลี่ยนแปลง</strong>ตามเป้าประสงค์นั้นโดยตรงชัดเจน
        ซึ่ง<strong>อาจเป็นตัวชี้วัดเชิงปริมาณหรือเชิงคุณภาพ</strong>ก็ได้แล้วแต่กรณี
        กล่าวคือ การวัดความสำเร็จอาจไม่ได้วัดออกมาเป็นค่าตัวเลขเสมอไป เช่น
        ความคืบหน้าในการจัดตั้งโรงไฟฟ้า
      </li>
      <li>
        ตัวชี้วัดความสำเร็จที่เป็น<strong>เชิงปริมาณ</strong>
        จะต้องระบุ<strong>หน่วยวัด</strong>ให้ชัดเจน
        และควรเป็นหน่วยวัดมาตรฐานของประเทศสำหรับตัวชี้วัดนั้น
        เพื่อประโยชน์ในการเปรียบเทียบและประมวลผลระดับประเทศต่อไป
      </li>
      <li>
        ค่า<strong>เป้าหมายผลลัพธ์</strong>ควรสะท้อนการเปลี่ยนแปลงอย่างมีนัยสำคัญ
        และมีความเป็นไปได้ สมเหตุสมผล
        โดยมี<strong>เกณฑ์อ้างอิง</strong>หรือคำอธิบายเหตุผลที่มาของ<strong
          >ค่าเป้าหมาย</strong
        >
        เช่น อิงมาจากค่าเป้าหมายระดับประเทศ
        หรือค่าเป้าหมายที่ได้กระจายลงสู่ระดับจังหวัดให้แล้ว(ถ้ามี)
        ซึ่งอาจนำมาปรับทอนได้บ้างเพื่อให้สอดคล้องกับกรอบเวลาหรือสถานการณ์เฉพาะของจังหวัด
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'GuidingTarget',

  computed: {
    ...mapGetters(['latestNationalPlan']),
  },
};
</script>
