<template>
  <div class="mb-4">
    <div class="box">
      <div class="box-header text-h4 font-weight-bold">
        <span v-if="mode == 'create'">สร้างเป้าหมาย</span>
        <span v-else>แก้ไขเป้าหมาย</span>
        <button
          @click="$modal.hide(name)"
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <eva-icon name="close-outline"></eva-icon>
        </button>
      </div>
      <div class="box-body">
        <div class="">
          <div class="form-group row">
            <label class="col-form-label col-2 font-weight-bold"
              >หัวข้อเป้าหมาย</label
            >
            <div class="col-8">
              <input
                type="text"
                class="form-control"
                placeholder=""
                v-model="targetForm.title"
              />
              <div class="error text-danger" v-if="targetForm.$v.title.$dirty">
                <div v-if="!targetForm.$v.title.required">โปรดระบุ</div>
                <div v-if="!targetForm.$v.title.maxLength">
                  เกินจำนวนตัวอักษรที่กำหนด
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-2 font-weight-bold">ประเภท</label>
            <div class="col-2">
              <select name="" v-model="targetForm.type" class="form-control">
                <option
                  v-for="item in targetTypes"
                  :key="item.id"
                  v-bind:value="item.id"
                >
                  {{ item.title }}
                </option>
              </select>
            </div>
            <div class="col-3">
              <input
                v-if="isTypeOther"
                type="text"
                class="form-control"
                placeholder="อื่นๆ"
                v-model="targetForm.type_other"
              />
              <div
                class="error text-danger"
                v-if="isTypeOther && targetForm.$v.type_other.$dirty"
              >
                <div v-if="!targetForm.$v.type_other.required">โปรดระบุ</div>
                <div v-if="!targetForm.$v.type_other.maxLength">
                  เกินจำนวนตัวอักษรที่กำหนด
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-2 align-items-start">
              <label class="col-form-label font-weight-bold">ตัวชี้วัด</label>
            </div>
            <div class="col-10">
              <div
                class="row align-items-center mb-2"
                v-for="(target_indicator, index) in targetForm.$v
                  .target_indicators.$each.$iter"
                :key="index"
              >
                <div class="col-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="รายละเอียด"
                    v-model="target_indicator.name.$model"
                  />
                  <div
                    class="error text-danger"
                    v-if="target_indicator.name.$dirty"
                  >
                    <div v-if="!target_indicator.name.required">โปรดระบุ</div>
                    <div v-if="!target_indicator.name.maxLength">
                      เกินจำนวนตัวอักษรที่กำหนด
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <input
                    type="number"
                    class="form-control"
                    v-model="target_indicator.value.$model"
                    placeholder="จำนวน"
                  />
                  <div
                    class="error text-danger"
                    v-if="target_indicator.value.$dirty"
                  >
                    <div v-if="!target_indicator.value.required">โปรดระบุ</div>
                  </div>
                </div>
                <div class="col-2">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="หน่วย"
                    v-model="target_indicator.unit.$model"
                  />
                  <div
                    class="error text-danger"
                    v-if="target_indicator.unit.$dirty"
                  >
                    <div v-if="!target_indicator.unit.required">โปรดระบุ</div>
                    <div v-if="!target_indicator.unit.maxLength">
                      เกินจำนวนตัวอักษรที่กำหนด
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  @click="removeTargetIndicator(index)"
                  class="btn btn-outline-red h-100 mt-1"
                >
                  ลบ
                </button>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <button class="btn btn-red" @click="addTargetIndicator">
                  <eva-icon name="plus-circle"></eva-icon>
                  เพิ่มตัวชี้วัด
                </button>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-12 font-weight-bold"
              >ผลลัพธ์สำคัญ</label
            >
            <div class="col-12">
              <div
                class="row mb-2"
                v-for="(target_result, index) in targetForm.$v.target_results
                  .$each.$iter"
                :key="index"
              >
                <div class="col-2">
                  <select
                    name=""
                    v-model="target_result.category.$model"
                    class="form-control"
                  >
                    <option
                      v-for="item in targeResultCategories"
                      :key="item.id"
                      v-bind:value="item.id"
                    >
                      {{ item.title }}
                    </option>
                  </select>
                </div>
                <div class="col-5">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="รายละเอียด"
                    v-model="target_result.name.$model"
                  />
                  <div
                    class="error text-danger"
                    v-if="target_result.name.$dirty"
                  >
                    <div v-if="!target_result.name.required">โปรดระบุ</div>
                    <div v-if="!target_result.name.maxLength">
                      เกินจำนวนตัวอักษรที่กำหนด
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <!--<vue-autonumeric-->
                  <!--class="form-control"-->
                  <!--v-model="target_result.value.$model"-->
                  <!--:options="$config.autoNumericOption(3)"-->
                  <!--placeholder="0"-->
                  <!--&gt;</vue-autonumeric>-->
                  <input
                    type="number"
                    class="form-control"
                    v-model="target_result.value.$model"
                    placeholder="จำนวน"
                  />
                  <div
                    class="error text-danger"
                    v-if="target_result.value.$dirty"
                  >
                    <div v-if="!target_result.value.required">โปรดระบุ</div>
                  </div>
                </div>
                <div class="col-2">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="หน่วย"
                    v-model="target_result.unit.$model"
                  />
                  <div
                    class="error text-danger"
                    v-if="target_result.unit.$dirty"
                  >
                    <div v-if="!target_result.unit.required">โปรดระบุ</div>
                    <div v-if="!target_result.unit.maxLength">
                      เกินจำนวนตัวอักษรที่กำหนด
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  @click="removeTargetResult(index)"
                  class="btn btn-outline-red h-100 mt-1"
                >
                  ลบ
                </button>
              </div>
              <div class="row">
                <div class="col-12">
                  <button class="btn btn-red" @click="addTargetResult">
                    <eva-icon name="plus-circle"></eva-icon>
                    เพิ่มผลลัพธ์สำคัญ
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-footer">
        <div class="form-group text-right mt-3">
          <button
            @click="deleteTarget"
            class="btn btn-outline-red mr-4"
            v-if="mode == 'edit'"
          >
            ลบเป้าหมายนี้
          </button>
          <button
            v-promise-btn
            @click="submit"
            class="btn btn-action"
            v-if="mode == 'create'"
          >
            สร้างเป้าหมาย
          </button>
          <button v-promise-btn @click="submit" class="btn btn-action" v-else>
            บันทึกการแก้ไข
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from '../modules/form/form';
import {
  required,
  requiredIf,
  sameAs,
  maxValue,
  maxLength,
} from 'vuelidate/lib/validators';
import { nth, reduce, cloneDeep, get } from 'lodash';

export default {
  name: 'TargetForm',

  components: {},

  props: {
    name: {
      type: String,
      required: true,
    },
    target: {
      type: Object,
      default: null,
    },
    mode: {
      type: String,
      default: 'create',
    },
    nationalStrategyID: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      targetTypes: [
        {
          id: 1,
          title: 'พลังงานทดแทน',
        },
        {
          id: 2,
          title: 'การอนุรักษ์พลังงาน',
        },
        {
          id: 3,
          title: 'อื่นๆ',
        },
      ],
      targeResultCategories: [
        {
          id: 1,
          title: 'เชิงเศรษฐกิจ',
        },
        {
          id: 2,
          title: 'เชิงสังคม',
        },
        {
          id: 3,
          title: 'เชิงสิ่งแวดล้อม',
        },
      ],
      targetForm: new Form({
        data: () => {
          return {
            national_strategy_id: this.nationalStrategyID,
            target_id: get(this.target, 'id', null),
            title: get(this.target, 'title', null),
            type: get(this.target, 'type', 1),
            type_other: get(this.target, 'type_other', null),
            // indicator_name: get(this.target, 'indicator_name', null),
            // indicator_value: get(this.target, 'indicator_value', null),
            // indicator_unit: get(this.target, 'indicator_unit', null),
            target_indicators: this.target
              ? cloneDeep(this.target.target_indicators)
              : [{ name: '', value: null, unit: '' }],
            target_results: this.target
              ? cloneDeep(this.target.target_results)
              : [{ category: 1, name: '', value: null, unit: '' }],
            config: {
              resetAfterSuccess: false,
              transformToPayload: (payload) => {
                payload.target_results = payload.target_results.filter(
                  (target_result) => {
                    return (
                      target_result.name &&
                      target_result.value &&
                      target_result.unit
                    );
                  }
                );
                payload.target_results.forEach((target_result) => {
                  if (
                    target_result.unit.trim().toLowerCase() ===
                    'ktoe'.toLowerCase()
                  ) {
                    target_result.ktoe = target_result.value;
                  }
                });
                return payload;
              },
            },
          };
        },
        validations: {
          title: {
            required,
            maxLength: maxLength(255),
          },
          type_other: {
            required: requiredIf((model) => {
              return this.isTypeOther;
            }),
            maxLength: maxLength(255),
          },
          // indicator_name: {
          //   required,
          //   maxLength: maxLength(255),
          // },
          // indicator_unit: {
          //   required,
          //   maxLength: maxLength(255),
          // },
          // indicator_value: {
          //   required,
          // },
          target_indicators: {
            $each: {
              name: {
                required,
                maxLength: maxLength(255),
              },
              value: {
                required,
              },
              unit: {
                required,
                maxLength: maxLength(255),
              },
            },
          },
          target_results: {
            $each: {
              category: {
                required,
              },
              name: {
                required,
                maxLength: maxLength(255),
              },
              value: {
                required,
              },
              unit: {
                required,
                maxLength: maxLength(255),
              },
            },
          },
        },
      }),
    };
  },

  created() {},

  computed: {
    isTypeOther() {
      return this.targetForm.type == 3;
    },
  },

  methods: {
    addTargetIndicator() {
      this.targetForm.target_indicators.push({
        name: '',
        value: 0,
        unit: '',
      });
    },
    removeTargetIndicator(index) {
      this.targetForm.target_indicators.splice(index, 1);
    },
    addTargetResult() {
      this.targetForm.target_results.push({
        category: 1,
        name: '',
        value: 0,
        unit: '',
      });
    },
    removeTargetResult(index) {
      this.targetForm.target_results.splice(index, 1);
    },
    submit() {
      return this.targetForm
        .patch('/api/current_report/targets/update')
        .then((data) => {
          this.$store.commit('peno/updateTarget', data);
          this.$modal.hide(this.name);
        })
        .catch((error) => {
          console.error(error);
          this.$toasted.global.display_error({
            message: error,
          });
        });
    },
    deleteTarget() {
      this.$vDialog.alert(
        'ต้องการลบเป้าหมายนี้',
        () => {
          window.axios
            .delete(`/api/current_report/targets/${this.target.id}`)
            .then((response) => {
              // this.$emit('onDeleted', this.targetStrategy);
              this.$store.commit('peno/deleteTarget', this.target);
              this.$modal.hide(this.name);
            })
            .catch((error) => {
              console.error(error);
            });
        },
        {
          messageType: 'confirm',
          title: 'ยืนยัน',
          iconClassName: '',
          isCaution: true,
          cancelCallback: () => {
            console.log('no');
          },
        }
      );
    },
  },
};
</script>

<style lang="scss"></style>
