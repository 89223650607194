<template>
  <section class="container-fluid mt-4">
    <div class="d-flex justify-content-between">
      <h5 class="font-weight-bold text-left d-flex align-items-baseline">
        ประเด็นพลังงานและเป้าหมายของจังหวัด{{ currentProvince.name_th }}
        <div
          class="text-gray text-medium pointer ml-3"
          v-tooltip="{
            content:
              'ข้อเขียนประเด็นพลังงานและเป้าหมายด้านพลังงานสำหรับจังหวัด รวมถึงตัวชี้วัดความสำเร็จตามเป้าหมาย พร้อมหน่วยวัดและค่าเป้าหมายผลลัพธ์',
          }"
        >
          <u>ข้อมูลเพิ่มเติม</u>
        </div>
      </h5>
      <div>
        <router-link
          :to="{ name: 'peno.swot' }"
          class="btn btn-outline-red py-1 px-4 mr-3"
        >
          วิเคราะห์ SWOT
        </router-link>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-md-5">
        <div class="box mb-4">
          <div class="box-header">
            <div class="text-ipepp">แผนปฏิบัติราชการกระทรวงพลังงาน</div>
          </div>
          <div class="box-body d-flex flex-column px-0 pt-0">
            <div
              class="px-3 py-3 border-bottom pointer"
              @click="selectNationalStrategy(index, strategy.id)"
              v-for="(strategy, index) in nationalPlan.national_strategies"
              v-bind:key="strategy.id"
              :class="{ 'strategy-selected': index == strategySelectedIndex }"
            >
              <div class="font-weight-bold d-flex justify-content-between">
                <div class="text-ipepp">
                  {{ parseInt(index) + 1 }}. {{ strategy.title }}
                </div>
                <eva-icon
                  v-visible="index == strategySelectedIndex"
                  class="eva-red"
                  name="arrow-ios-forward-outline"
                ></eva-icon>
              </div>
              <div
                class="mt-2"
                v-for="areaPlanStategy in areaPlanStrategies(strategy.id)"
                :key="areaPlanStategy.id"
              >
                <h6 class="mb-0 font-italic">
                  ประเด็นยุทธศาสตร์ที่ {{ areaPlanStategy.strategy_number }}
                </h6>
                <p class="mb-2">{{ areaPlanStategy.name }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="box mb-5">
          <div class="box-header">
            <div class="text-ipepp">
              ภาพรวมประเด็นด้านพลังงานที่ปรากฏในแผนพัฒนาเชิงพื้นที่
              <span
                class="pointer text-blue"
                @click="showGuidingStrategyOverviewModal()"
              >
                <u>ดูคำแนะนำ</u>
              </span>
            </div>
          </div>
          <div class="box-body d-flex flex-column">
            <form
              action=""
              @submit.prevent="submitProvinceStrategyOverview"
              v-promise-btn="{ action: 'submit' }"
            >
              <div class="form-group">
                <label class=""
                  >ประเด็นด้านพลังงานที่ได้รับการบรรจุในแผนพัฒนาจังหวัด</label
                >
                <textarea
                  class="form-control"
                  name="province_strategy_overview"
                  rows="7"
                  v-model="provinceStrategyForm.province_strategy_overview"
                ></textarea>
              </div>
              <div class="form-group">
                <label class=""
                  >ประเด็นด้านพลังงานที่ได้รับการบรรจุในแผนพัฒนากลุ่มจังหวัด</label
                >
                <textarea
                  class="form-control"
                  rows="7"
                  name="province_group_strategy_overview"
                  v-model="
                    provinceStrategyForm.province_group_strategy_overview
                  "
                ></textarea>
              </div>
              <div class="text-right">
                <button type="submit" class="btn btn-action" v-show="allowEdit">
                  บันทึก
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-7">
        <div class="box">
          <div class="box-header d-flex justify-content-between">
            <div class="text-ipepp">
              เป้าหมายด้านพลังงานจังหวัด{{ this.currentProvince.name_th }}
              <span class="pointer text-blue" @click="showGuidingModal()">
                <u>ดูคำแนะนำ</u>
              </span>
            </div>
            <button
              class="btn py-0 btn-red"
              @click="modalTargetForm(null)"
              v-show="allowEdit"
            >
              <eva-icon name="plus"></eva-icon>
              เพิ่มเป้าหมาย
            </button>
          </div>
          <div class="box-body pt-0 pb-5 pr-0 pl-3">
            <div v-show="selectedTargets.length == 0" class="pl-2 pt-3">
              ยังไม่มีเป้าหมาย สำหรับแผนแม่บทนี้
              <a
                v-show="allowEdit"
                href="#"
                @click="modalTargetForm(null)"
                class="text-red font-weight-bold text-underline"
                >เพิ่มเป้าหมาย</a
              >
            </div>
            <div
              class="text-ipepp pl-2 pr-4 pb-3 pt-3 border-bottom"
              v-for="(target, index) in selectedTargets"
              v-bind:key="target.id"
            >
              <div class="font-weight-bold mb-2 d-flex justify-content-between">
                เป้าหมายที่ {{ parseInt(index) + 1 }}
                <button
                  class="btn py-0"
                  @click="modalTargetForm(target)"
                  v-show="allowEdit"
                >
                  <eva-icon name="edit"></eva-icon>
                  แก้ไข
                </button>
              </div>
              <div class="mb-3">{{ target.title }}</div>
              <div class="mb-3">
                <div class="">ตัวชี้วัด</div>
                <div
                  class="text-light-ipepp"
                  v-for="target_indicator in target.target_indicators"
                  :key="target_indicator.id"
                >
                  {{
                    `${target_indicator.name} ${target_indicator.value} ${target_indicator.unit}`
                  }}
                </div>
              </div>
              <div class="mb-3">
                <div class="">ผลลัพธ์สำคัญ</div>
                <div
                  class="text-light-ipepp"
                  v-for="target_result in target.target_results"
                  :key="target_result.id"
                >
                  {{
                    `${target_result.category_title} - ${target_result.name} ${target_result.value} ${target_result.unit}`
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modals-container></modals-container>
  </section>
</template>

<script>
import Form from '@/modules/form/form';
import TargetForm from '@/components/TargetForm.vue';
import GuidingModal from '@/components/GuidingModal.vue';
import GuidingTarget from '@/components/guiding/GuidingTarget.vue';
import GuidingStrategyOverview from '@/components/guiding/GuidingStrategyOverview.vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { head, find, filter } from 'lodash';

export default {
  name: 'targets',

  data() {
    return {
      strategySelectedIndex: 0,
      strategySelectedID: null,
      strategySelectedClass:
        'strategy-selected border-top border-left border-right rounded-top bg-white',
      provinceStrategyForm: null,
    };
  },

  created() {
    this.strategySelectedID = head(this.nationalPlan.national_strategies).id;
    this.provinceStrategyForm = new Form({
      data: () => {
        return {
          report_id: this.currentReport.id,
          province_strategy_overview:
            this.currentReport.province_strategy_overview,
          province_group_strategy_overview:
            this.currentReport.province_group_strategy_overview,
          config: {
            resetAfterSuccess: false,
          },
        };
      },
      validations: {},
    });
  },

  mounted() {
    window.onbeforeunload = () => {
      if (this.provinceStrategyForm.isDirty()) {
        return 'แน่ใจหรือที่จะออกจากหน้านี้? มีข้อความที่ยังไม่ได้บันทึก!';
      } else {
        return void 0;
      }
    };
  },

  beforeDestroy() {
    window.onbeforeunload = null;
  },

  computed: {
    ...mapGetters(['latestNationalPlan']),
    ...mapState('peno', ['currentReport']),
    ...mapGetters('peno', ['currentProvince', 'allowEdit']),
    nationalPlan() {
      return this.latestNationalPlan;
    },
    selectedAreaPlanStrategies() {
      return filter(this.currentReport.area_plan_strategies, {
        national_strategy_id: this.strategySelectedID,
      });
    },
    selectedTargets() {
      return filter(this.currentReport.targets, {
        national_strategy_id: this.strategySelectedID,
      });
    },
  },

  methods: {
    // indicator(target) {
    //   // return `${target.indicator_name} (${target.indicator_type}) ${target.indicator_value} ${target.indicator_unit}`;
    //   return `${target.indicator_name} (${target.indicator_type}) ${target.indicator_value} ${target.indicator_unit}`;
    // },
    selectNationalStrategy(index, strategyID) {
      this.strategySelectedIndex = index;
      this.strategySelectedID = strategyID;
    },
    areaPlanStrategies(nationalStrategyId) {
      return filter(this.currentReport.area_plan_strategies, {
        national_strategy_id: nationalStrategyId,
      });
    },
    showGuidingModal() {
      this.$modal.show(
        GuidingModal,
        {
          name: 'guiding_modal',
          title: '',
          component: GuidingTarget,
        },
        {
          name: 'guiding_modal',
          pivotY: 0.01,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '80%',
          transition: 'fade',
        }
      );
    },
    showGuidingStrategyOverviewModal() {
      this.$modal.show(
        GuidingModal,
        {
          name: 'guiding_strategy_modal',
          title: '',
          component: GuidingStrategyOverview,
        },
        {
          name: 'guiding_strategy_modal',
          pivotY: 0.01,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '80%',
          transition: 'fade',
        }
      );
    },
    modalTargetForm(target = null) {
      const mode = target ? 'edit' : 'create';
      this.$modal.show(
        TargetForm,
        {
          name: 'target_form',
          mode: mode,
          nationalStrategyID: this.strategySelectedID,
          target: target,
        },
        {
          name: 'target_form',
          pivotY: 0.01,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '80%',
          transition: 'fade',
        }
      );
    },
    submitProvinceStrategyOverview() {
      return this.provinceStrategyForm
        .patch('/api/current_report/province_strategy_overview')
        .then((data) => {
          this.$store.commit('peno/updateProvinceStrategyOverview', {
            provinceStrategyOverview: data.province_strategy_overview,
            provinceGroupStrategyOverview:
              data.province_group_strategy_overview,
          });
        })
        .catch((error) => {
          console.error(error);
          this.$toasted.global.display_error({
            message: error,
          });
        });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.provinceStrategyForm.isDirty()) {
      const answer = window.confirm(
        'แน่ใจหรือที่จะออกจากหน้านี้? มีข้อความที่ยังไม่ได้บันทึก!'
      );
      if (answer) {
        next();
      }
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/variables';

.strategy-selected {
  background-color: $lighter-red-color;
}
</style>
